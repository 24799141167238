/* eslint-disable react-hooks/exhaustive-deps */
import _ from 'lodash'
import React, {
  useCallback, useEffect, useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { cancelRequest, useArticles, useI18n } from 'react-omnitech-api'
import { useAlert, useLink } from '../../hook'
import ArticleView from './article-view'

function ArticleController(props) {
  const { id: articleId, filterType, filterSlug } = props

  // prepare hook
  const alert = useAlert()
  const { recordNotFound } = useLink()
  const { t } = useTranslation()
  const { fetchArticle } = useArticles()
  const { currentLanguage } = useI18n()

  // internal states
  const [article, setArticle] = useState({})
  const [breadcrumb, setBreadcrumb] = useState([])
  const [pageReady, setPageReady] = useState(false)

  // local variable
  const baseLink = {
    text: t('screens.article.baseBreadcrumb'),
    url: '/articles/',
  }
  const seoTitle = _.get(article, 'seoTitle') || article.title
  const seoDescription = _.get(article, 'seoDescription') || article.excerpt
  const seoMeta = [{
    name: 'keyword',
    content: _.get(article, 'seoKeywords'),
  }]
  const seoLinks = [{
    rel: 'canonical',
    href: _.get(article, 'canonicalHref'),
  }]

  /**
   * fetchArticleApi
   * get article content group data from API
   */
  const fetchArticleApi = useCallback(async () => {
    try {
      // api call option
      const option = {
        id: articleId,
        includes: [
          'article_categories',
          'images',
        ].join(','),
      }
      // call api
      const { article: data } = await fetchArticle(option)
      setArticle(data)
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      if (generalError.code === 404) {
        recordNotFound()
        return
      }
      alert.show(generalError.message)
    } finally {
      setPageReady(true)
    }
  }, [fetchArticle, articleId, currentLanguage])

  /**
   * set breadcrumb base on article and current filter type, such as category and tag
   */
  const updateBreadcrumb = useCallback((articleData, urlFilterType, urlFilterSlug) => {
    const breadcrumbArray = []
    // add base link
    breadcrumbArray.push(baseLink)
    // if article is opened under a filter, add to breadcrumb
    switch (urlFilterType) {
      case 'category':
        {
          const { articleCategories } = articleData
          const currentCategory = _.find(articleCategories, { code: urlFilterSlug })
          if (!_.isEmpty(currentCategory)) {
            breadcrumbArray.push({
              text: currentCategory.name,
              url: `/articles/category/${currentCategory.code}/`,
            })
          }
        }
        break
      case 'tag':
        {
          const { tags } = articleData
          const isTagExist = _.includes(tags, urlFilterSlug)
          if (isTagExist) {
            breadcrumbArray.push({
              text: urlFilterSlug,
              url: `/articles/tag/${urlFilterSlug}/`,
            })
          }
        }
        break
      default:
        break
    }
    // add article title
    breadcrumbArray.push({
      text: articleData.title,
    })
    setBreadcrumb(breadcrumbArray)
  }, [])

  /**
   * load article content group when page loaded
   */
  useEffect(() => {
    fetchArticleApi()

    return function fetchArticleApiCleanUp() {
      cancelRequest.cancelAll([
        'fetchArticle',
      ])
    }
  }, [fetchArticleApi])

  /**
   * set breadcrumb after article is ready
   */
  useEffect(() => {
    if (_.isEmpty(article)) return

    updateBreadcrumb(article, filterType, filterSlug)
  }, [updateBreadcrumb, article, filterType, filterSlug])

  const viewProps = {
    article,
    breadcrumb,
    pageReady,
    seoDescription,
    seoMeta,
    seoTitle,
    seoLinks,
  }

  return (
    <ArticleView {...viewProps} />
  )
}

export default ArticleController
